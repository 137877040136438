/*
 * @Author: your name
 * @Date: 2022-05-05 13:57:11
 * @LastEditTime: 2024-10-23 10:02:07
 * @LastEditors: 张维杰 15536847978@163.com
 * @Description: 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 * @FilePath: /saas-pc/src/plugin/operation_manager/service/index.js
 */
import _axios, { post, get, put } from '@/lin/plugin/axios'

class CsMng {
  //营业额统计
  async business(data) {
    const res = await get(`/adminChain/re/report/business`, data)
    return res
  }

  //会员营收统计
  async customer(data) {
    const res = await get(`/adminChain/re/report/business/customer`, data)
    return res
  }

  //会员营收明细
  async customerList(data) {
    const res = await get(`/adminChain/re/report/business/customer/list`, data)
    return res
  }
  //库存营收明细
  async stockList(data) {
    const res = await get(`/adminChain/report/business/stock/list`, data)
    return res
  }
  //查询会员等级列表
  async getLevelList(data) {
    const res = await get(`/adminChain/customer/level/list/saas`, data)
    return res
  }
  //支付营收统计
  async payment(data) {
    const res = await get(`/adminChain/re/report/business/payment`, data)
    return res
  }
  //导出储值订单列表
  async exportOrderCardStored(data) {
    const res = await _axios({
      url: '/adminChain/order/exportOrderCardStored',
      method: 'POST',
      data,
      responseType: 'blob',
    })
    return res
  }

  //门店
  async getStoreList(data = {}) {
    if ('list' in data && data.list.length != 0) {
    } else {
      data.list = ['0']
    }
    data.isContainStop = 1
    const res = await post(`/adminChain/organizational/saas/getCheckStoreListByOrganizationalCodeList`, data)
    return res
  }

  //重跑结账单等报表
  async reportRepeatRunBill(data) {
    const res = await get(`/report/reportRepeatRunBill`, data)
    return res
  }

  //重跑班次营收等报表
  async reportRepeatRunOpenRevenue(data) {
    const res = await get(`/reportJob/reportRepeatRunOpenRevenueByMysql`, data)
    return res
  }

  //重跑场次，方式，超市等报表
  async reportRepeatRunOpenRoomGoods(data) {
    const res = await get(`/reportJob/reportRepeatRunOpenRoomGoodsDataBase`, data)
    return res
  }

  //重跑储值营收等报表
  async reportRepeatRunStoredCardsRevenue(data) {
    const res = await get(`/reportJob/reportRepeatRunStoredCardsRevenue`, data)
    return res
  }

  //多日期、多项目一起重跑表
  async reportDataBaseBetweenMonth(data) {
    const res = await get(`/reportJob/reportDataBaseBetweenMonth`, data)
    return res
  }

  //多日期、多项目一起重跑表
  async reportDataBaseBetweenMonthNew(data) {
    const res = await get(`/shadow/report/reportDataBaseBetweenMonth`, data)
    return res
  }

  //查询门店区域关联门店详情
  getRegionList(data) {
    return get('/adminChain/store/saas/storeRegionConfig/getRegionList', data)
  }
  //查询区域-》权限
  async getCheckArea() {
    let data = {
      isOnlyEnable: 1,
    }
    const res = await get(`/adminChain/organizational/saas/getCheckArea`, data)
    return res
  }

  //列表
  async queryBoxManagerListPage(data = {}) {
    return await post(`/adminChain/saas/storeCheck/queryListPage`, data)
  }

  //上报状态
  async syncReportStatus(data = {}) {
    return await get(`/adminChain/saas/storeCheck/syncReportStatus`, data)
  }
  //关台
  async syncStoreRoomClose(data = {}) {
    return await get(`/adminChain/saas/storeCheck/syncStoreRoomClose`, data)
  }
  //同步信息
  async syncStoreRoomMacAndIp(data = {}) {
    return await get(`/adminChain/saas/storeCheck/syncStoreRoomMacAndIp`, data)
  }
  //开台
  async syncStoreRoomOpen(data = {}) {
    return await get(`/adminChain/saas/storeCheck/syncStoreRoomOpen`, data)
  }

  exportReport({ url, data }) {
    return _axios({
      url,
      method: 'POST',
      data,
      responseType: 'blob',
    })
  }

  //优惠券核销
  //用户优惠券列表
  async queryCouponByUserCode(data = {}) {
    return await post(`/adminChain/coupon/saas/queryCouponByUserCode`, data)
  }

  //核销优惠券
  async useCustomerCoupon(data = {}) {
    return await post(`/adminChain/coupon/saas/useCustomerCoupon`, data)
  }

  //版本管理
  async insertVersionApk(data = {}) {
    return await post(`/adminChain/saas/insertVersionApk`, data)
  }
  async queryVersionApkPage(data = {}) {
    return await post(`/adminChain/saas/queryVersionApkPage`, data)
  }
  async releaseVersion(data = {}) {
    return await get(`/adminChain/saas/releaseVersion`, data)
  }
  async updateVersionApk(data = {}) {
    return await post(`/adminChain/saas/updateVersionApk`, data)
  }
  async deleteVersion(data = {}) {
    return await get(`/adminChain/saas/deleteVersion`, data)
  }

  //修改手机号
  async getMemberList(data) {
    const res = await post(`/adminChain/customer/list/saas/page`, data)
    return res
  }
  async checkOrUpdateCustomerPhone(data) {
    const res = await post(`/adminChain/customer/checkOrUpdateCustomerPhone`, data)
    return res
  }
  async forceUpdatesCustomerPhone(data) {
    const res = await post(`/adminChain/customer/forceUpdatesCustomerPhone`, data)
    return res
  }

  //取酒验证码
  async sendWineSmsCode(data) {
    const res = await get(`/adminChain/wineAccess/sendWineSmsCode`, data)
    return res
  }

  //优惠券助手
  //分页查询优惠券规则模版列表
  selectCouponPage(data) {
    return post(`/adminChain/coupon/saas/selectCouponPage`, data)
  }
  //创建批量发券
  addSendCoupon(data) {
    return post(`/adminChain/coupon/saas/addSendCoupon`, data)
  }
  //分页查询发券日志
  pageSendCouponLog(data) {
    return post(`/adminChain/coupon/saas/pageSendCouponLog`, data)
  }
  //查询发券日志详情
  querySendCouponLogDetails(data) {
    return post(`/adminChain/coupon/saas/querySendCouponLogDetails`, data)
  }
  //查询设备管理分页列表
  queryStoreMacPage(data) {
    return post(`/adminChain/store/mac/queryStoreMacPage`, data)
  }
  //设备管理解绑/注册
  updateByDeviceState(data) {
    return post(`/adminChain/store/mac/updateByDeviceState`, data)
  }
  //设备管理-更换主收银
  updateByCashierType(data) {
    return post(`/adminChain/store/mac/updateByCashierType`, data)
  }

  // 获取配置
  getLibrary(data) {
    return get('/adminChain/custom-library/get', data)
  }
  // 编辑配置
  updateLibrary(data) {
    return post('/adminChain/custom-library/update', data)
  }


  //利通会员迁移
  litongPageList(data) {
    return get('/adminChain/litong/pageList', data)
  }
  litongRemark(data) {
    return post('/adminChain/litong/remark', data)
  }
  litongSyncData(data) {
    return get('/adminChain/litong/sync', data)
  }
}

export default new CsMng()
